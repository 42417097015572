import axios from "axios";
import { Urls } from "../utils/urls";
import { Constants } from "../utils/constants";
import Toasted from "vue-toasted";
import Vue from "vue";
Vue.use(Toasted, {
  theme: "bubble",
  iconPack: "custom-class",
  position: "top-right",
  duration: 1500,
  action: [
    {
      icon: "fas fa-times",
      onClick: (e, toastObject) => {
        toastObject.goAway(0);
      },
    },
  ],
});

const baseURL = Constants.URL_BE_API;
const userRequestServer = axios.create({
  baseURL: baseURL,
  timeout: 600000,
  headers: {
    "Content-type": "application/json",
  },
});

const adminRequestServer = axios.create({
  baseURL: baseURL,
  timeout: 600000,
  headers: {
    "Content-type": "application/json",
  },
});

const cdeaRequestServer = axios.create({
  baseURL: baseURL,
  timeout: 600000,
  headers: {
    "Content-type": "application/json",
  },
});

userRequestServer.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_USER);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete userRequestServer.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

userRequestServer.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status === 500 &&
      !error.config.url.includes("log-activity")
    ) {
      const request = { url: location.href };
      axios
        .post(baseURL + "/send-email-error-url", request)
        .then((response) => (this.info = response));
    }
    return Promise.reject(error);
  }
);

adminRequestServer.interceptors.request.use(
  (config) => {
    const check = window.location.pathname.includes("/shop/");
    var id;
    if (window.location.pathname.includes(Constants.ADMIN)) {
      if (check) {
        id = parseInt(window.location.pathname.split("/")[2]);
      }
    } else {
      if (check) {
        id = parseInt(window.location.pathname.split("/")[2]);
      }
    }
    const extConstant = id
      ? "_" + Constants.DOMAIN + "_" + id
      : "_" + Constants.DOMAIN;
    const token = localStorage.getItem(Constants.TOKEN_ADMIN + extConstant);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete userRequestServer.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

adminRequestServer.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 500) {
      Vue.toasted.show(
        "システムエラーが発生しました。エラーはシステム担当者に自動通知されましたので、回答までお待ちください"
      );
      const request = { url: location.href };
      axios
        .post(baseURL + "/send-email-error-url", request)
        .then((response) => (this.info = response));
    }
    return Promise.reject(error);
  }
);

cdeaRequestServer.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_CDEA);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete userRequestServer.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

const requestDownload = axios.create({
  baseURL: Urls.BASE_URL_SERVER_1,
  timeout: 10000,
  responseType: "blob",
});

requestDownload.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_USER);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete requestDownload.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

const requestForm = axios.create({
  baseURL: baseURL,
  timeout: 600000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

requestForm.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_USER);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete requestForm.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

export const Api = {
  userRequestServer,
  adminRequestServer,
  cdeaRequestServer,
  requestDownload,
  requestForm,
};
