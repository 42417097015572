<template>
  <div id="app">
    <router-view />
    <!-- function loading when redirect screen momentary -->
    <div>
      <b-modal
        id="loading-redirect"
        hide-header
        hide-footer
        no-close-on-backdrop
        v-model="modalShow"
      >
        <div class="text-center" style="margin: 10px">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="d-block text-center">
          <div>ページ移動中です。少々お待ちくださいませ。</div>
          <div>時間が経っても移動しない場合はお問い合わせください。</div>
        </div>
      </b-modal>
    </div>
    <b-modal
      id="loading-page"
      hide-header
      hide-footer
      no-close-on-backdrop
      v-model="modalLoadingPage"
    >
      <div class="text-center" style="margin: 10px">
        <div class="position-relative w-100 d-flex justify-content-center">
          <div class="mikepad-loading">
            <div class="binding"></div>
            <div class="pad">
              <div class="line line1"></div>
              <div class="line line2"></div>
              <div class="line line3"></div>
            </div>
            <div class="text">ページ移動中</div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// require("./assets/js/all.js");
import { mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters(["modalShow", "modalLoadingPage"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.$store.commit("set", ["modalLoadingPage", false]);
      },
    },
    deep: true,
  },
};
</script>
<style>
@import url("./assets/css/all.min.css");
</style>
<style lang="scss">
// Import Main styles for this application\
@import "assets/scss/style";
@import "assets/css/style";
@import "assets/css/add";
</style>
