/* eslint-disable no-unused-vars */
import { data, nodeName } from "jquery";
import Vue from "vue";
import Vuex from "vuex";
import { Constants } from "../utils/constants";
import { Api } from "../utils/http-common";
import { Urls } from "../utils/urls";
import LogRocket from "logrocket";
import createPlugin from "logrocket-vuex";
import router from "../router";
LogRocket.init("paracel-miet1/logrocket_cdea");
LogRocket.identify("1", {
  name: "Paracel",
  email: "longtv@dn.paracelsoft.com",
});
const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

function redirectLoginShop(error = null) {
  console.log(error);
  const req = {
    user_id: localStorage.getItem(Constants.USER_ID),
    shop_id: localStorage.getItem(Constants.SHOP_ID),
  };
  // return { success: false };
  Api.userRequestServer
    .post(`/realtime-activity/delete`, req)
    .then(() => {})
    .catch(() => {});
  localStorage.removeItem(Constants.TOKEN_USER);
  localStorage.removeItem(Constants.USER_TYPE_USER);
  localStorage.removeItem(Constants.EXPIRES_AT_USER);
  localStorage.removeItem(Constants.USER_ID);
  localStorage.removeItem(Constants.EMAIL_USER);
  localStorage.removeItem("popupSend");
  localStorage.removeItem(Constants.NORMAL_USER_INFO);
  localStorage.removeItem(Constants.IS_REMEMBER_USER);
  router.push({
    name: router.currentRoute.params.shopId
      ? "login shop"
      : "login shop domain",
  });
}

function redirectLoginAdmin(error = null) {
  const extConstant = router.currentRoute.params.shopId
    ? "_" + Constants.DOMAIN + "_" + router.currentRoute.params.shopId
    : "_" + Constants.DOMAIN;
  localStorage.removeItem(Constants.TOKEN_ADMIN);
  localStorage.removeItem(Constants.USER_TYPE_ADMIN);
  localStorage.removeItem(Constants.SHOP_NAME);
  localStorage.removeItem(Constants.ADMIN_USER_INFO);
  localStorage.removeItem(Constants.EXPIRES_AT_ADMIN);
  localStorage.removeItem(Constants.EMAIL_ADMIN);
  localStorage.removeItem(Constants.TOKEN_ADMIN + extConstant);
  localStorage.removeItem(Constants.USER_TYPE_ADMIN + extConstant);
  localStorage.removeItem(Constants.SHOP_NAME + extConstant);
  localStorage.removeItem(Constants.ADMIN_USER_INFO + extConstant);
  localStorage.removeItem(Constants.EXPIRES_AT_ADMIN + extConstant);
  localStorage.removeItem(Constants.IS_REMEMBER_ADMIN);
  localStorage.removeItem(Constants.EMAIL_ADMIN + extConstant);
  router.push({
    name: router.currentRoute.params.shopId
      ? "login admin"
      : "login admin domain",
  });
}

export default new Vuex.Store({
  state: {
    offerType: {},
    offerPrice: {},
    offerShop: {},
    //Owner
    listOwner: [],
    owner: {},
    // Slag
    listSlag: [],
    slagById: {},
    // Content
    listContent: [],
    contentById: {},
    listFilter: [],
    detailTypeContent: {},
    detailBasicContent: {},
    detailAdvanceContent: {},
    detailOfferContent: {},
    // UnpurChasedContent
    listUnpurChasedContent: [],
    // List Author
    listAuthorFilter: [],
    listAuthorSuggest: [],
    tag_id: [],
    authorById: {},
    contentAuthor: [],
    // List Categories
    listCategoriesInShop: [],
    // List Slag
    listSlagInShop: [],
    // Page
    pageById: {},
    listPage: [],
    success: false,
    successFavorite: false,
    successSlagCondition: false,
    message: "",
    error: false,
    //Library
    contentInLibrary: [],
    contentPage: [],
    contentChapter: [],
    listAuthorLibrary: [],
    listCategoryLibrary: [],
    //User
    listUser: [],
    listDataAuthor: [],
    userDetail: {},
    userProfile: {},
    purchaseHistorySubscription: [],
    purchaseHistoryOneTime: [],
    purchaseHistoryAll: [],
    //Cart
    listCart: [],
    amountCart: 0,
    listContentCart: [],
    listNoContent: [],
    //CDEA
    listUserCDEA: [],
    listShop: [],
    // checkout
    detailShop: {},
    listOrder: [],
    // note
    listNote: [],
    noteById: {},
    //setting point
    listSettingPoint: [],
    settingPointById: {},
    pointBonusHistory: [],
    allHistoryPoint: [],
    pointOfUser: [],

    //Q&A
    listQA: [],
    qaById: [],

    //Page_id
    pageId: null,
    //payment info
    paymentInfos: [],

    //category
    listCategory: [],
    categoryById: {},
    loading: false,
    listNoteShop: [],
    listNoteShopSP: [],
    ListNoteByContent: [],
    listFilterNote: [],

    //order
    orderById: {},
    orderByIdInUser: {},
    checkOrderDuplicateUser: {},

    //data CSV
    dataCSV: [],
    contentLibraryById: [],

    // catalogueById
    catalogueById: [],

    //note user
    listUserNote: [],
    //dataLibrary
    dataLibrary: [],
    listLibraryAuthor: [],
    listLibraryCategory: [],
    //dataStore
    dataStore: [],
    dataRecommend: [],
    // data Sale Managetment Monthly
    listSaleManagementMonthly: [],
    // data Sale Management Daily
    listSaleManagementDaily: [],
    // data detail Sale Management Daily
    listDetailSaleManagementDaily: [],
    // data sum Sale Managetment Monthly
    dataSumSaleManagementMonthly: {},
    // data Sale Management Rank
    listSaleManagementRank: [],
    dateSaleManagementRank: [],

    //inquiry
    listInquiry: [],
    formInquiry: [],

    //survey
    listSurvey: [],
    detailSurvey: {},

    // new
    listNew: [],
    detailNew: [],
    listCategoryNew: [],
    detailCategoryNew: [],
    listNewAdmin: [],
    detailNewAdmin: [],
    listNewAdminDashboard: [],

    detailStore: {},
    detailcontentPreSales: {},

    //setting page
    listHeader: [],
    listFooter: [],
    listBottom: [],
    //Setting Page
    listSettingPageHeader: [],
    listSettingPageFooter: [],

    //List tag
    listTag: [],

    //fixed page
    listFixedPage: [],
    detailFixedPage: {},
    detailFixedPageByUser: {},
    detailFixed: {},

    //list data Timing Pop-up
    listTimingPopup: [],
    timingPopupById: {},

    //list popup
    listPopUp: [],
    //list setting mail
    listSettingMail: [],

    // list Timing mail
    listTimingMail: [],
    timingMailById: {},
    timingMailUserSend: {},

    listAuthor: [],

    //URL Manager
    listUrl: [],
    detailUrl: [],
    listUrlUser: [],

    // general setting
    generalsetting: {},

    //Sale setting
    saleSettingById: {},

    //SNS Setting
    SNSSetting: {},

    isDeleteSuccessCart: false,
    ListSettingShop: [],
    //list slag user
    listSlagUser: [],

    //list note all user
    listNoteAllUser: [],

    //list cart admin
    listCartAdmin: [],

    //list Mail Mega
    listMailMega: [],
    mailMegaById: {},

    //list order suncription
    listOrderSub: [],

    //List Chapter
    listChapter: [],
    listAllChapter: [],
    chapterById: {},

    //List Purchase Point
    listPurchasePoint: [],
    PurchasePointById: {},

    //Chapter Setting
    listChapterSetting: [],
    ChapterSettingByContent: {},

    URLByToken: {},

    countNewUnread: 0,

    InfoContentPayment: {},
    showMenu: false,
    type: 0,
    isActive0: true,
    isActive1: false,
    isActive2: false,
    isActive3: false,
    isActive4: false,
    isActive5: false,
    dis0: "none",
    dis1: "none",
    dis2: "none",
    dis3: "none",
    dis4: "none",
    dis5: "none",
    countContentUnread: 0,
    dataGeneral: {},
    showHambuger: false,
    activeIndexMenu: "",
    activeIndexMenuPart: "",
    isShowButton: false,
    listContentShop: [],
    IdContent: null,
    noteByContent: {},
    checkHeaderImg: "",
    checkBackgroundImg: "",
    checkArticleImg: "",
    dataConditionChapter: {},
    dataConditionChapterPage: {},
    listContents: [],
    listAllContents: [],
    listChat: [],
    listMessageByChatID: [],
    ListChatByUser: [],
    isShowNote: false,
    refer_url: "",
    isShowButtonChatOrNote: false,
    listnoteUINote: [],
    isShowLayout: false,
    isShowMenu: "",
    idNoteLog: null,
    idNewsLog: null,
    routerNext: null,
    modalShow: true,
    modalLoadingPage: false,
    clickOnPDF: false,
    listCoupon: [],
    detailCoupon: [],
    detailCouponChecked: [],
    listSentence: [],
    detailSentence: [],
    detailCheckSentence: [],
    idModalFixed: "",
    detailMyProfileSetting: "",
    detailMyProfileSettingUser: "",
    detailDesignSetting: "",
    detailDesignSettingUser: "",

    listFixedQuestion: [],
  },
  getters: {
    offerType: (state) => state.offerType,
    offerPrice: (state) => state.offerPrice,
    offerShop: (state) => state.offerShop,
    //Owner
    listOwner: (state) => state.listOwner,
    owner: (state) => state.owner,
    // Slag
    listSlag: (state) => state.listSlag,
    slagById: (state) => state.slagById,
    //User
    listUser: (state) => state.listUser,
    listDataAuthor: (state) => state.listDataAuthor,
    userDetail: (state) => state.userDetail,
    userProfile: (state) => state.userProfile,
    purchaseHistorySubscription: (state) => state.purchaseHistorySubscription,
    purchaseHistoryOneTime: (state) => state.purchaseHistoryOneTime,
    purchaseHistoryAll: (state) => state.purchaseHistoryAll,
    // Content
    listContent: (state) => state.listContent,
    listNoContent: (state) => state.listNoContent,
    contentById: (state) => state.contentById,
    listFilter: (state) => state.listFilter,
    detailTypeContent: (state) => state.detailTypeContent,
    detailBasicContent: (state) => state.detailBasicContent,
    detailAdvanceContent: (state) => state.detailAdvanceContent,
    detailOfferContent: (state) => state.detailOfferContent,
    // UnpurChased Content
    listUnpurChasedContent: (state) => state.listUnpurChasedContent,
    // List Author
    listAuthor: (state) => state.listAuthor,
    listAuthorFilter: (state) => state.listAuthorFilter,
    listAuthorSuggest: (state) => state.listAuthorSuggest,
    authorById: (state) => state.authorById,
    contentAuthor: (state) => state.contentAuthor,
    // List Categories
    listCategoriesInShop: (state) => state.listCategoriesInShop,
    // List Slags
    listSlagInShop: (state) => state.listSlagInShop,
    success: (state) => state.success,
    message: (state) => state.message,
    successFavorite: (state) => state.successFavorite,
    successSlagCondition: (state) => state.successSlagCondition,
    error: (state) => state.error,
    // Page
    listPage: (state) => state.listPage,
    pageById: (state) => state.pageById,
    //Library
    contentInLibrary: (state) => state.contentInLibrary,
    contentPage: (state) => state.contentPage,
    contentChapter: (state) => state.contentChapter,
    listAuthorLibrary: (state) => state.listAuthorLibrary,
    listCategoryLibrary: (state) => state.listCategoryLibrary,
    //Cart
    listCart: (state) => state.listCart,
    amountCart: (state) => state.amountCart,
    listContentCart: (state) => state.listContentCart,
    //CDEA
    listUserCDEA: (state) => state.listUserCDEA,
    listShop: (state) => state.listShop,
    // Checkout
    detailShop: (state) => state.detailShop,
    listOrder: (state) => state.listOrder,
    // Note
    listNote: (state) => state.listNote,
    noteById: (state) => state.noteById,
    //setting point
    listSettingPoint: (state) => state.listSettingPoint,
    settingPointById: (state) => state.settingPointById,
    pointBonusHistory: (state) => state.pointBonusHistory,
    allHistoryPoint: (state) => state.allHistoryPoint,
    pointOfUser: (state) => state.pointOfUser,

    //Q&A
    listQA: (state) => state.listQA,
    qaById: (state) => state.qaById,

    //Page_id
    pageId: (state) => state.pageId,
    paymentInfos: (state) => state.paymentInfos,

    // Category
    listCategory: (state) => state.listCategory,
    categoryById: (state) => state.categoryById,
    loading: (state) => state.loading,
    //List Note shop
    listNoteShop: (state) => state.listNoteShop,
    listNoteShopSP: (state) => state.listNoteShopSP,

    // Order by id
    orderById: (state) => state.orderById,
    orderByIdInUser: (state) => state.orderByIdInUser,
    checkOrderDuplicateUser: (state) => state.checkOrderDuplicateUser,

    contentLibraryById: (state) => state.contentLibraryById,

    //ListNoteByContent
    ListNoteByContent: (state) => state.ListNoteByContent,
    listFilterNote: (state) => state.listFilterNote,

    // catalogueById
    catalogueById: (state) => state.catalogueById,

    //List user note
    listUserNote: (state) => state.listUserNote,

    // dataLibrary
    dataLibrary: (state) => state.dataLibrary,
    listLibraryAuthor: (state) => state.listLibraryAuthor,
    listLibraryCategory: (state) => state.listLibraryCategory,

    // dataStore
    dataStore: (state) => state.dataStore,
    dataRecommend: (state) => state.dataRecommend,

    //list inquiry
    listInquiry: (state) => state.listInquiry,
    formInquiry: (state) => state.formInquiry,

    //list survey
    listSurvey: (state) => state.listSurvey,
    detailSurvey: (state) => state.detailSurvey,

    // dataNew
    listNew: (state) => state.listNew,
    detailNew: (state) => state.detailNew,
    listCategoryNew: (state) => state.listCategoryNew,
    detailCategoryNew: (state) => state.detailCategoryNew,
    listNewAdmin: (state) => state.listNewAdmin,
    listNewAdminDashboard: (state) => state.listNewAdminDashboard,
    detailNewAdmin: (state) => state.detailNewAdmin,
    // data SaleManagetment Monthly
    listSaleManagementMonthly: (state) => state.listSaleManagementMonthly,
    // data SaleManagetment Daily
    listSaleManagementDaily: (state) => state.listSaleManagementDaily,

    // data SumSaleManagement Monthly
    dataSumSaleManagementMonthly: (state) => state.dataSumSaleManagementMonthly,

    // data detail SaleManagetment Daily
    listDetailSaleManagementDaily: (state) =>
      state.listDetailSaleManagementDaily,
    detailStore: (state) => state.detailStore,
    detailcontentPreSales: (state) => state.detailcontentPreSales,

    //setting page
    listHeader: (state) => state.listHeader,
    listFooter: (state) => state.listFooter,
    listBottom: (state) => state.listBottom,
    //Setting Page
    listSettingPageHeader: (state) => state.listSettingPageHeader,
    listSettingPageFooter: (state) => state.listSettingPageFooter,

    //List tag
    listTag: (state) => state.listTag,
    listSaleManagementRank: (state) => state.listSaleManagementRank,
    dateSaleManagementRank: (state) => state.dateSaleManagementRank,

    //Fixed page
    listFixedPage: (state) => state.listFixedPage,
    detailFixedPage: (state) => state.detailFixedPage,
    detailFixed: (state) => state.detailFixed,

    // Timing Popup
    listTimingPopup: (state) => state.listTimingPopup,
    timingPopupById: (state) => state.timingPopupById,

    //list PopUp
    listPopUp: (state) => state.listPopUp,
    //list setting mail
    listSettingMail: (state) => state.listSettingMail,

    //list Timing Mail
    listTimingMail: (state) => state.listTimingMail,
    timingMailById: (state) => state.timingMailById,
    timingMailUserSend: (state) => state.timingMailUserSend,

    //URL Manager
    listUrl: (state) => state.listUrl,
    detailUrl: (state) => state.detailUrl,
    listUrlUser: (state) => state.listUrlUser,

    //general setting
    generalsetting: (state) => state.generalsetting,

    //Sale setting
    saleSettingById: (state) => state.saleSettingById,

    //SNS Setting
    SNSSetting: (state) => state.SNSSetting,

    isDeleteSuccessCart: (state) => state.isDeleteSuccessCart,
    ListSettingShop: (state) => state.ListSettingShop,

    listSlagUser: (state) => state.listSlagUser,

    listNoteAllUser: (state) => state.listNoteAllUser,

    listCartAdmin: (state) => state.listCartAdmin,

    //Mail Mega
    listMailMega: (state) => state.listMailMega,
    mailMegaById: (state) => state.mailMegaById,

    listOrderSub: (state) => state.listOrderSub,

    detailFixedPageByUser: (state) => state.detailFixedPageByUser,

    //Chpater Admin
    listChapter: (state) => state.listChapter,
    listAllChapter: (state) => state.listAllChapter,
    chapterById: (state) => state.chapterById,

    // Purchase Point
    listPurchasePoint: (state) => state.listPurchasePoint,
    PurchasePointById: (state) => state.PurchasePointById,

    //Chpater Setting
    listChapterSetting: (state) => state.listChapterSetting,
    ChapterSettingByContent: (state) => state.ChapterSettingByContent,

    //Coupon
    listCoupon: (state) => state.listCoupon,
    detailCoupon: (state) => state.detailCoupon,
    detailCouponChecked: (state) => state.detailCouponChecked,

    //Sentence
    listSentence: (state) => state.listSentence,
    detailSentence: (state) => state.detailSentence,
    detailCheckSentence: (state) => state.detailCheckSentence,

    //URL By Token
    URLByToken: (state) => state.URLByToken,
    countNewUnread: (state) => state.countNewUnread,

    //Get Info Content before Login
    InfoContentPayment: (state) => state.InfoContentPayment,
    showMenu: (state) => state.showMenu,
    type: (state) => state.type,
    isActive0: (state) => state.isActive0,
    isActive1: (state) => state.isActive1,
    isActive2: (state) => state.isActive2,
    isActive3: (state) => state.isActive3,
    isActive4: (state) => state.isActive4,
    isActive5: (state) => state.isActive5,
    dis1: (state) => state.dis1,
    dis2: (state) => state.dis2,
    dis3: (state) => state.dis3,
    dis4: (state) => state.dis4,
    dis5: (state) => state.dis5,
    countContentUnread: (state) => state.countContentUnread,
    dataGeneral: (state) => state.dataGeneral,
    showHambuger: (state) => state.showHambuger,
    activeIndexMenu: (state) => state.activeIndexMenu,
    activeIndexMenuPart: (state) => state.activeIndexMenuPart,
    isShowButton: (state) => state.isShowButton,
    listContentShop: (state) => state.listContentShop,
    IdContent: (state) => state.IdContent,
    noteByContent: (state) => state.noteByContent,
    checkHeaderImg: (state) => state.checkHeaderImg,
    checkBackgroundImg: (state) => state.checkBackgroundImg,
    checkArticleImg: (state) => state.checkArticleImg,
    dataConditionChapter: (state) => state.dataConditionChapter,
    dataConditionChapterPage: (state) => state.dataConditionChapterPage,
    listContents: (state) => state.listContents,
    listAllContents: (state) => state.listAllContents,
    listChat: (state) => state.listChat,
    listMessageByChatID: (state) => state.listMessageByChatID,
    ListChatByUser: (state) => state.ListChatByUser,
    isShowNote: (state) => state.isShowNote,
    refer_url: (state) => state.refer_url,
    isShowButtonChatOrNote: (state) => state.isShowButtonChatOrNote,
    listnoteUINote: (state) => state.listnoteUINote,
    isShowLayout: (state) => state.isShowLayout,
    isShowMenu: (state) => state.isShowMenu,
    idNoteLog: (state) => state.idNoteLog,
    idNewsLog: (state) => state.idNewsLog,
    routerNext: (state) => state.routerNext,
    modalShow: (state) => state.modalShow,
    modalLoadingPage: (state) => state.modalLoadingPage,
    clickOnPDF: (state) => state.clickOnPDF,
    idModalFixed: (state) => state.idModalFixed,
    detailMyProfileSetting: (state) => state.detailMyProfileSetting,
    detailMyProfileSettingUser: (state) => state.detailMyProfileSettingUser,
    detailDesignSetting: (state) => state.detailDesignSetting,
    detailDesignSettingUser: (state) => state.detailDesignSettingUser,
    listFixedQuestion: (state) => state.listFixedQuestion,
  },
  mutations: {
    set(state, [variable, value]) {
      state[variable] = value;
    },
    removeItem(state, [variable, value]) {
      state[variable] = state[variable].filter(function (item) {
        return item.id !== value;
      });
    },
  },
  actions: {
    //set page_id
    setPageId({ commit }, pageId) {
      commit("set", ["pageId", pageId]);
    },
    voteContent({ commit }, request) {
      const api = Api.userRequestServer;
      api
        .post(`/${Urls.VOTE}`, request)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async addFavorite({ commit }, request) {
      const api = Api.userRequestServer;
      return new Promise((resolve, reject) => {
        api.post(`/${Urls.CONTENT}/${Urls.FAVORITE}`, request).then(
          (response) => {
            if (response.status == 401) {
              redirectLoginShop();
            }
            const { data } = response;
            if (data.success) {
              commit("set", ["message", data.message]);
              commit("set", ["successFavorite", true]);
            } else {
              commit("set", ["message", data.message]);
              commit("set", ["error", true]);
            }
            resolve(response); // Let the calling function know that http is done. You may send some data back
          },
          (error) => {
            if (error.response.status == 401) {
              redirectLoginShop();
            } else {
              console.log(error);
            }
            // http failed, let the calling function know that action did not work out
            reject(error);
          }
        );
      });
    },
    getAllPurchaseHistory({ commit }, request) {
      Api.userRequestServer
        .post(`/${Urls.ORDER}/${Urls.LIST}/${Urls.ALL}`, request.data)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            if (request.data["type"] === "subscription") {
              commit("set", ["purchaseHistorySubscription", data.data]);
            } else {
              commit("set", ["purchaseHistoryAll", data.data]);
            }
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getPurchaseHistorySubscriptionAdmin({ commit }, request) {
      request.data["type"] = "subscription";
      Api.adminRequestServer
        .post(
          `/${Urls.ORDER}/${Urls.LIST}?${Urls.PAGE}=${request.page}`,
          request.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["purchaseHistorySubscription", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getPurchaseHistorySubscriptionUser({ commit }, request) {
      request.data["type"] = "subscription";
      Api.userRequestServer
        .post(
          `/${Urls.ORDER}/${Urls.LIST}?${Urls.PAGE}=${request.page}`,
          request.data
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["purchaseHistorySubscription", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getPurchaseHistoryOneTimeAdmin({ commit }, request) {
      const url = Api.adminRequestServer;
      request.data["type"] = "onetime";
      url
        .post(
          `/${Urls.ORDER}/${Urls.LIST}?${Urls.PAGE}=${request.page}`,
          request.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["purchaseHistoryOneTime", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getPurchaseHistoryOneTimeUser({ commit }, request) {
      const url = Api.userRequestServer;
      request.data["type"] = "onetime";
      url
        .post(
          `/${Urls.ORDER}/${Urls.LIST}?${Urls.PAGE}=${request.page}`,
          request.data
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["purchaseHistoryOneTime", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListSettingMail({ commit }, shopId) {
      Api.adminRequestServer
        .get(`/${Urls.SETTING}/${Urls.MAIL}?shop_id=${shopId}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listSettingMail", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    saveSMTP({ commit }, request) {
      Api.adminRequestServer
        .post(`/${Urls.SETTING}/${Urls.SMTP}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    saveMail({ commit }, request) {
      Api.adminRequestServer
        .post(`/${Urls.SETTING}/${Urls.MAIL}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    testMail({ commit }, request) {
      Api.adminRequestServer
        .post(`/${Urls.TEST_SEND_MAIL}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async cancelSubscriptionAdmin({ commit }, request) {
      const api = Api.adminRequestServer;
      const url =
        request.type === "stripe"
          ? `/${Urls.STRIPE}/${Urls.CANCEL_SUB}`
          : `/${Urls.PAYPAL}/${Urls.CANCEL_SUB}`;
      await api
        .post(url, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async cancelSubscriptionUser({ commit }, request) {
      const api = Api.userRequestServer;
      const url =
        request.type === "stripe"
          ? `/${Urls.STRIPE}/${Urls.CANCEL_SUB}`
          : `/${Urls.PAYPAL}/${Urls.CANCEL_SUB}`;
      return await api
        .post(url, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getFormInquiry({ commit }, shopId) {
      const api = Api.userRequestServer;
      api
        .get(`/${Urls.INQUIRY}/${Urls.DETAIL}?shop_id=${shopId}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["formInquiry", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    createFormInquiry({ commit }, request) {
      const api = Api.userRequestServer;
      api
        .post(`/${Urls.INQUIRY}/${Urls.CREATE}`, request)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getOfferType({ commit }, request) {
      Api.adminRequestServer
        .get(
          `/${Urls.CONTENT}/${Urls.OFFER_TYPE}/${request.content_id}?shop_id=${request.shop_id}`,
          request
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["offerType", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateOfferType({ commit }, request) {
      Api.adminRequestServer
        .patch(
          `/${Urls.CONTENT}/${Urls.OFFER_TYPE}/${request.content_id}`,
          request
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getOfferPrice({ commit }, request) {
      return await Api.adminRequestServer
        .get(
          `/${Urls.CONTENT}/${Urls.OFFER_PRICE}/${request.content_id}?shop_id=${request.shop_id}`,
          request
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["offerPrice", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateOfferPrice({ commit }, request) {
      Api.adminRequestServer
        .patch(
          `/${Urls.CONTENT}/${Urls.OFFER_PRICE}/${request.content_id}`,
          request
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getOfferShop({ commit }, request) {
      Api.adminRequestServer
        .get(
          `/${Urls.CONTENT}/${Urls.OFFER_SHOP}/${request.content_id}?shop_id=${request.shop_id}`,
          request
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["offerShop", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateOfferShop({ commit }, request) {
      Api.adminRequestServer
        .post(
          `/${Urls.CONTENT}/${Urls.OFFER_SHOP}/${request.content_id}`,
          request.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListSurvey({ commit }, request) {
      Api.adminRequestServer
        .post(`/${Urls.SURVEY}?${Urls.PAGE}=${request.page}`, request.data)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listSurvey", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    deleteSurvey({ commit }, request) {
      Api.adminRequestServer
        .delete(`/${Urls.SURVEY}/${request.id}?shop_id=${request.shopId}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    createAnswerSurvey({ commit }, request) {
      Api.userRequestServer
        .post(`/${Urls.STORE_ANSWER}`, request)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    createSurvey({ commit }, request) {
      Api.adminRequestServer
        .post(`/${Urls.SURVEY}/${Urls.CREATE}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getSurveyById({ commit }, request) {
      Api.adminRequestServer
        .get(`/${Urls.SURVEY}/${request.id}?shop_id=${request.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailSurvey", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateSurvey({ commit }, request) {
      Api.adminRequestServer
        .patch(`/${Urls.SURVEY}/${request.id}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListInquiry({ commit }, request) {
      Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.QA}/${Urls.LIST}?${Urls.PAGE}=${request.page}`,
          request.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listInquiry", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    createInquiry({ commit }, request) {
      return Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.QA}/${Urls.CREATE_EDIT}`,
          request
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateStatusInquiry({ commit }, request) {
      Api.adminRequestServer
        .patch(
          `/${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.QA}/${request.id}`,
          request
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    deleteInquiry({ commit }, id) {
      Api.adminRequestServer
        .delete(`/${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.QA}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateOfferContent({ commit }, request) {
      Api.adminRequestServer
        .patch(
          `/${Urls.CONTENT}/${Urls.SALE_OFFER}/${request.content_id}`,
          request
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getDetailOfferContent({ commit }, request) {
      Api.adminRequestServer
        .get(
          `/${Urls.CONTENT}/${Urls.SALE_OFFER}/${request.id}?shop_id=${request.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["detailOfferContent", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateAdvanceContent({ commit }, request) {
      Api.adminRequestServer
        .patch(
          `/${Urls.CONTENT}/${Urls.SALE_ADVANCE}/${request.content_id}`,
          request
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getDetailAdvanceContent({ commit }, request) {
      Api.adminRequestServer
        .get(
          `/${Urls.CONTENT}/${Urls.SALE_ADVANCE}/${request.id}?shop_id=${request.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["detailAdvanceContent", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateBasicContent({ commit }, request) {
      Api.adminRequestServer
        .patch(
          `/${Urls.CONTENT}/${Urls.SALE_BASIC}/${request.content_id}`,
          request
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getDetailBasicContent({ commit }, request) {
      Api.adminRequestServer
        .get(
          `/${Urls.CONTENT}/${Urls.SALE_BASIC}/${request.id}?shop_id=${request.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["detailBasicContent", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateTypeContent({ commit }, request) {
      Api.adminRequestServer
        .patch(
          `/${Urls.CONTENT}/${Urls.SALE_OTHER}/${request.content_id}`,
          request
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getDetailTypeContent({ commit }, request) {
      Api.adminRequestServer
        .get(
          `/${Urls.CONTENT}/${Urls.SALE_OTHER}/${request.id}?shop_id=${request.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["detailTypeContent", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListNewAdminDashboard({ commit }, request) {
      Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.NEWS_ADMIN}/${Urls.LIST}?${Urls.PAGE}=${request.page}`,
          request.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listNewAdminDashboard", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListNewAdmin({ commit }, request) {
      Api.cdeaRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.NEWS_ADMIN}?${Urls.PAGE}=${request.page}`,
          request.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listNewAdmin", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    createNewAdmin({ commit }, request) {
      Api.cdeaRequestServer
        .post(`/${Urls.ADMIN}/${Urls.NEWS_ADMIN}/${Urls.CREATE}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getNewAdminById({ commit }, id) {
      Api.cdeaRequestServer
        .get(`/${Urls.ADMIN}/${Urls.NEWS_ADMIN}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["detailNewAdmin", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateNewAdmin({ commit }, request) {
      Api.cdeaRequestServer
        .patch(`/${Urls.ADMIN}/${Urls.NEWS_ADMIN}/${request.id}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    deleteNewAdmin({ commit }, id) {
      Api.cdeaRequestServer
        .delete(`/${Urls.ADMIN}/${Urls.NEWS_ADMIN}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListNew({ commit }, request) {
      const api =
        request.screen === "user" && localStorage.getItem(Constants.TOKEN_USER)
          ? Api.userRequestServer
          : request.screen === "admin" ||
            (request.screen === "user" &&
              localStorage.getItem(Constants.TOKEN_ADMIN))
          ? Api.adminRequestServer
          : Api.cdeaRequestServer;
      api
        .post(
          `/${Urls.ADMIN}/${Urls.NEWS}?${Urls.PAGE}=${request.page}`,
          request.data
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listNew", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    createNew({ commit }, request) {
      Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.NEWS}/${Urls.CREATE}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateNew({ commit }, request) {
      Api.adminRequestServer
        .patch(`/${Urls.ADMIN}/${Urls.NEWS}/${request.id}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getNewByIdAdmin({ commit }, request) {
      const apiRequest = Api.adminRequestServer;
      apiRequest
        .get(
          `/${Urls.ADMIN}/${Urls.NEWS}/${request.id}?shop_id=${request.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailNew", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getNewByIdUser({ commit }, request) {
      const apiRequest = Api.userRequestServer;
      apiRequest
        .get(
          `/${Urls.ADMIN}/${Urls.NEWS}/${request.id}?shop_id=${request.shop_id}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["detailNew", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    deleteNew({ commit }, request) {
      const api =
        request.screen === "admin"
          ? Api.adminRequestServer
          : Api.cdeaRequestServer;
      api
        .delete(
          `/${Urls.ADMIN}/${Urls.NEWS}/${request.id}?shop_id=${request.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateTimeRead({ commit }, request) {
      const url = `/${Urls.ADMIN}/${Urls.NEWS}/${Urls.UPDATE_TIME_READ}`;
      const api = Api.userRequestServer;
      api
        .post(url, request)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getUnreadNew({ commit }, request) {
      const url = `/${Urls.NEWS}/${Urls.UNREAD}`;
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .post(url, request)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["countNewUnread", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getUnreadContent({ commit }, request) {
      const url = `/${Urls.LIBRARY}/${Urls.CONTENT}/${Urls.UNREAD}/${request.shop_id}`;
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .get(url)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["countContentUnread", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getListCategoryNew({ commit }, request) {
      const api =
        request.screen === "user" && localStorage.getItem(Constants.TOKEN_USER)
          ? Api.userRequestServer
          : request.screen === "admin" ||
            (request.screen === "user" &&
              localStorage.getItem(Constants.TOKEN_ADMIN))
          ? Api.adminRequestServer
          : Api.cdeaRequestServer;
      await api
        .post(`/${Urls.ADMIN}/${Urls.CATEGORY_NEW}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listCategoryNew", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getCategoryNewById({ commit }, request) {
      Api.adminRequestServer
        .get(
          `/${Urls.ADMIN}/${Urls.CATEGORY_NEW}/${request.id}/?shop_id=${request.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailCategoryNew", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    createCategoryNew({ commit }, request) {
      Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.CATEGORY_NEW}/${Urls.CREATE}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateCategoryNew({ commit }, request) {
      Api.adminRequestServer
        .patch(`/${Urls.ADMIN}/${Urls.CATEGORY_NEW}/${request.id}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    deleteCategoryNew({ commit }, request) {
      Api.adminRequestServer
        .delete(
          `/${Urls.ADMIN}/${Urls.CATEGORY_NEW}/${request.id}?shop_id=${request.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // call api forgot password && change password
    async sendMailForgotPassword({ commit }, emailData) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      await api
        .post(`/${Urls.SEND_MAIL_PASSWORD}`, emailData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    changePassword({ commit }, emailData) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .post(`/${Urls.RESET_PASSWORD}`, emailData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end call api forgot password && change password

    // api Admin
    // call api slag
    async getListSlag({ commit }, formData) {
      await Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.SLAG}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listSlag", data.data]);
          } else {
            commit("set", ["listSlag", []]);
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getALLlistSlag({ commit }, request) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.SLAG}/${Urls.LIST}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listSlag", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    CreateSlag({ commit }, formCreate) {
      Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.SLAG}/${Urls.CREATE}`, formCreate)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    deleteSlag({ commit }, data) {
      Api.adminRequestServer
        .delete(
          `/${Urls.ADMIN}/${Urls.SLAG}/${data.id}?shop_id=${data.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
            commit("removeItem", ["listSlag", data.id]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getSlagById({ commit }, formData) {
      Api.adminRequestServer
        .get(
          `/${Urls.ADMIN}/${Urls.SLAG}/${formData.id}?shop_id=${formData.shop_id}`,
          formData
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["slagById", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateSlagById({ commit }, formUpdate) {
      return await Api.adminRequestServer
        .patch(`/${Urls.ADMIN}/${Urls.SLAG}/${formUpdate.id}`, formUpdate)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end call api slag

    // call api page
    getListPage({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.PAGE}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listPage", data.data]);
          } else if (data.message.trim() === "ページは存在しません。") {
            let copy = { ...this.listPage };
            copy.data = [];
            commit("set", ["listPage", copy]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    //option page
    getListPageOption({ commit }, req) {
      Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.ALL_PAGE}`, req)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listPage", data]);
          } else if (data.message.trim() === "ページは存在しません。") {
            let copy = { ...this.listPage };
            copy.data = [];
            commit("set", ["listPage", copy]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async getPageById({ commit }, data) {
      const api = Api.adminRequestServer;
      return await api
        .post(`/${Urls.ADMIN}/${Urls.PAGE}/${data.id}?shop_id=${data.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["pageById", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updatePageByID({ commit }, formUpdate) {
      return await Api.adminRequestServer
        .patch(
          `/${Urls.ADMIN}/${Urls.PAGE}/${formUpdate.id}?shop_id=${formUpdate.shop_id}`,
          formUpdate
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end api page

    getListOwner({ commit }, formData) {
      Api.cdeaRequestServer
        .get(
          `/${Urls.OWNER}?${Urls.PAGE}=${formData.page}&limit=${formData.limit}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listOwner", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getOwnerById({ commit }, id) {
      Api.cdeaRequestServer
        .get(`/${Urls.OWNER}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["owner", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    updateOwner({ commit }, formData) {
      Api.cdeaRequestServer
        .patch(`/${Urls.OWNER}/${formData.id}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    deleteOwner({ commit }, formData) {
      Api.cdeaRequestServer
        .delete(`/${Urls.OWNER}/${formData.id}?shop_id=${formData.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          commit("set", ["message", data.message]);
          commit("set", ["error", true]);
        });
    },

    // createOwnerDefault({ commit }, formData) {
    //   Api.adminRequestServer
    //     .post(`/${Urls.SENDMAIL_TOADMIN}`, formData)
    //     .then((response) => {
    //       const { data } = response;
    //       if (data.success) {
    //         commit("set", ["message", data.message]);
    //         commit("set", ["success", true]);
    //       } else {
    //         commit("set", ["message", data.message]);
    //         commit("set", ["error", true]);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    createOwnerMaster({ commit }, formData) {
      Api.cdeaRequestServer
        .post(`/${Urls.OWNER}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end call api content

    async getListCategoriesInShop({ commit }, request) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.CATEGORY}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listCategoriesInShop", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async CreateCategory({ commit }, formCreate) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.CATEGORY}/${Urls.CREATE}`, formCreate)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListAuthorInShop({ commit }, request) {
      Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.AUTHOR}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listAuthor", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // getListSlagInShop({ commit }) {
    //   let shop_id = this.$route.params.shopId;
    //   const paramData = {
    //     shop_id: shop_id,
    //   };
    //   Api.userRequestServer
    //     .post(
    //       `/store/list-all-slagname`,
    //       paramData
    //     )
    //     .then((response) => {
    //       const { data } = response;
    //       if (data.success) {
    //         commit("set", ["listSlagInShop", data.data]);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // end api store

    //api get user
    async getListUser({ commit }, formData) {
      await Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.USER}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listUser", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getContentLibraryById({ commit }, payload) {
      const { id, shop_id } = payload;
      const api = Api.userRequestServer;
      api
        .get(`${Urls.LIBRARY}/${Urls.CONTENT}/${id}?shop_id=${shop_id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["contentLibraryById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 404) {
            router.push({
              name: router.currentRoute.params.shopId
                ? "page404"
                : "page404 domain",
            });
          }
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getCatalogue({ commit }, payload) {
      const { id, content_id } = payload;
      const api = Api.userRequestServer;
      await api
        .get(`${Urls.CATALOGUE}/${id}?content_id=${content_id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["catalogueById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end call api content

    // call api page

    getProfile({ commit }, formData) {
      const url = `/${Urls.PROFILE}?shop_id=${formData.shop_id}`;
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .get(url)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["userProfile", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async updateProfile({ commit }, formData) {
      const url = `/${Urls.PROFILE}`;
      const api = Api.userRequestServer;
      return await api
        .patch(url, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getUserById({ commit }, formData) {
      const url = `/${Urls.ADMIN}/${Urls.USER}/${formData.id}?shop_id=${formData.shop_id}`;
      Api.adminRequestServer
        .get(url)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["userDetail", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async createUserOwner({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.USER}/${Urls.CREATE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            const dataUser = data.data;
            commit("set", ["message", "サインアップの成功"]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    blockUser({ commit }, formData) {
      Api.adminRequestServer
        .post(`/${Urls.ADMIN_USER}/${Urls.BLOCK}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", "成功をブロックする"]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getDataAuthor({ commit }) {
      let shop_id = this.$route.params.shopId;
      const formData = {
        email: "",
        nickname: "",
        user_type: "3",
        shop_id: shop_id,
      };
      Api.adminRequestServer
        .post(`/${Urls.ADMIN_USER}/${Urls.FILTER}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listDataAuthor", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateUserOwner({ commit }, formData) {
      const url = `/${Urls.ADMIN}/${Urls.USER}/${formData.id}`;
      return await Api.adminRequestServer
        .patch(url, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    //api user cdea
    getListUserCDEA({ commit }) {
      const formData = {
        email: "",
        nickname: "",
        user_type: "",
        shop_id: "",
      };
      Api.adminRequestServer
        .post(
          `${process.env.VUE_APP_ROOT_API}/${Urls.ADMIN_USER}/${Urls.FILTER}`,
          formData
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listUserCDEA", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    createUserCDEA({ commit }, formData) {
      Api.adminRequestServer
        .post(`/${Urls.ADMIN_USER}/${Urls.CREATE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", "サインアップの成功"]);
            commit("set", ["success", true]);
            console.log(data);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updateUserCDEA({ commit }, formData) {
      Api.adminRequestServer
        .post(`/${Urls.ADMIN_USER}/${Urls.EDIT}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", "正常に編集"]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    destroyUserCDEA({ commit }, formData) {
      Api.adminRequestServer
        .delete(
          `/${Urls.ADMIN}/${Urls.USER}/${formData.id}?shop_id=${formData.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", "成功をブロックする"]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListShop({ commit }) {
      Api.adminRequestServer
        .get(`/${Urls.ADMIN_USER}/${Urls.LIST_SHOP_ID}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listShop", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //end api user cdea

    // api category
    async getListCategory({ commit }, formData) {
      await Api.adminRequestServer
        .post(
          `${Urls.ADMIN}/${Urls.CATEGORY}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listCategory", data.data]);
          } else if (data.message.trim() === "カテゴリは存在しません。") {
            let copy = { ...this.listCategory };
            copy.data = [];
            commit("set", ["listCategory", copy]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async CreateCategoryAdmin({ commit }, formCreate) {
      return await Api.adminRequestServer
        .post(`${Urls.ADMIN}/${Urls.CATEGORY}/${Urls.CREATE}`, formCreate)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async deleteCategory({ commit }, data) {
      return await Api.adminRequestServer
        .delete(
          `${Urls.ADMIN}/${Urls.CATEGORY}/${data.id}?shop_id=${data.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getCategoryById({ commit }, data) {
      Api.adminRequestServer
        .get(
          `${Urls.ADMIN}/${Urls.CATEGORY}/${data.id}?shop_id=${data.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["categoryById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateCategoryByID({ commit }, formCategory) {
      return await Api.adminRequestServer
        .patch(
          `${Urls.ADMIN}/${Urls.CATEGORY}/${formCategory.id}?shop_id=${formCategory.shop_id}`,
          formCategory
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end api category
    // Api Order
    getListOrder({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.ORDER}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listOrder", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getOrderById({ commit }, data) {
      return await Api.adminRequestServer
        .get(`/${Urls.ADMIN}/${Urls.ORDER}/${data.id}?shop_id=${data.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["orderById", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateStatusOrderId({ commit }, formData) {
      return Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.ORDER}/${Urls.UPDATE_STATUS}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    exportCSVOrder({ commit }, formData) {
      Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.ORDER}/export-order`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["orderById", data.data]);
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async deleteOrder({ commit }, formData) {
      return await Api.adminRequestServer
        .delete(
          `/${Urls.ADMIN}/${Urls.ORDER}/${formData.id}?shop_id=${formData.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async getOrderByIdInUser({ commit }, data) {
      return await Api.userRequestServer
        .get(`/${Urls.ADMIN}/${Urls.ORDER}/${data.id}?shop_id=${data.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["orderByIdInUser", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async checkOrderDuplicate({ commit }, data) {
      return await Api.userRequestServer
        .get(
          `/${Urls.ORDER}/${Urls.CHECK_DUPLICATE}/${data.id}?shop_id=${data.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["checkOrderDuplicateUser", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // end API Order

    // Api Author
    async getListAuthor({ commit }, formData) {
      return await Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.AUTHOR}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listAuthor", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async deleteAuthor({ commit }, data) {
      return await Api.adminRequestServer
        .delete(
          `/${Urls.ADMIN}/${Urls.AUTHOR}/${data.id}?shop_id=${data.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async CreateAuthor({ commit }, formCreate) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.AUTHOR}/${Urls.CREATE}`, formCreate)
        .then((response) => {
          let data = response.data;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
            return data;
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
            return data;
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          return { success: false };
        });
    },

    updateAuthorByID({ commit }, formCreate) {
      return Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.AUTHOR}/${formCreate.get("id")}`,
          formCreate
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          commit("set", ["loading", false]);
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    //detail author
    getAuthorById({ commit }, formData) {
      Api.adminRequestServer
        .get(
          `/${Urls.ADMIN}/${Urls.AUTHOR}/${formData.id}?shop_id=${formData.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["authorById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // end Api AUthor

    //list user note
    getListUserNote({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.USER}/${formData.id}/${Urls.NOTE}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listUserNote", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //create content
    async CreateContent({ commit }, formCreate) {
      return await Api.adminRequestServer
        .post(`/${Urls.CONTENT}`, formCreate)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            // commit("set", ["message", data.message]);
            // commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //update content
    async updateContentById({ commit }, formUpdate) {
      return await Api.adminRequestServer
        .post(`/${Urls.CONTENT}`, formUpdate)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            // commit("set", ["message", data.message]);
            // commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateSettingContentById({ commit }, formUpdate) {
      return await Api.adminRequestServer
        .post(`/${Urls.CONTENT}/${Urls.SETTING}`, formUpdate)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            // commit("set", ["message", data.message]);
            // commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //detail content
    async getContentById({ commit }, reqData) {
      return await Api.adminRequestServer
        .get(`/${Urls.CONTENT}/${reqData.id}?shop_id=${reqData.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["contentById", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getContentByIdUser({ commit }, reqData) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .get(`/${Urls.CONTENT}/${reqData.id}?shop_id=${reqData.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["contentById", data.data]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getHeaderSetting({ commit }, reqData) {
      Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.HEADER}/${Urls.LIST}`,
          reqData
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listHeader", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateHeader({ commit }, formCreate) {
      return await Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.HEADER}/${Urls.CREATE_EDIT}`,
          formCreate
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getFooterSetting({ commit }, reqData) {
      Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.FOOTER}/${Urls.LIST}`,
          reqData
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listFooter", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateFooter({ commit }, formCreate) {
      return await Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.FOOTER}/${Urls.CREATE_EDIT}`,
          formCreate
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getBottomSetting({ commit }, reqData) {
      const url = `/${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.BOTTOM}/${Urls.LIST}`;
      return await Api.adminRequestServer
        .post(url, reqData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listBottom", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getBottomSettingType({ commit }, reqData) {
      const api = Api.userRequestServer;
      return await api
        .post(
          `/${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.BOTTOM}/${Urls.LIST}`,
          reqData
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listBottom", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateBottom({ commit }, formBottom) {
      return await Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.BOTTOM}/${Urls.CREATE_EDIT}`,
          formBottom
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListFixed({ commit }, request) {
      return Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.FIXED_PAGE}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listFixedPage", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async deleteFixedPage({ commit }, data) {
      return await Api.adminRequestServer
        .delete(
          `/${Urls.ADMIN}/${Urls.FIXED_PAGE}/${data.id}?shop_id=${data.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async CreateFixedPage({ commit }, request) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.FIXED_PAGE}/${Urls.CREATE}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getFixedPageById({ commit }, reqData) {
      Api.adminRequestServer
        .get(`/${Urls.ADMIN}/${Urls.FIXED_PAGE}/${reqData.id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailFixedPage", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async UpdateFixedPage({ commit }, request) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.FIXED_PAGE}/${Urls.UPDATE}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListURL({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.URL}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listUrl", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async deleteURL({ commit }, data) {
      return await Api.adminRequestServer
        .delete(`/${Urls.ADMIN}/${Urls.URL}/${data.id}?shop_id=${data.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async CreateURL({ commit }, request) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.URL}/${Urls.CREATE}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getUrlById({ commit }, reqData) {
      Api.adminRequestServer
        .get(
          `/${Urls.ADMIN}/${Urls.URL}/${reqData.id}?shop_id=${reqData.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailUrl", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async UpdateURL({ commit }, request) {
      return await Api.adminRequestServer
        .patch(`/${Urls.ADMIN}/${Urls.URL}/${request.id}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async CreatePaymentSetting({ commit }, request) {
      return await Api.adminRequestServer
        .post(`/${Urls.SETTING}/${Urls.PAYMENT}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["message", error.message]);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //List Note All User
    getListNoteAllUser({ commit }, req) {
      return Api.adminRequestServer
        .post(`/${Urls.NOTE}?${Urls.PAGE}=${req.page}`, req.data)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listNoteAllUser", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //List Cart Admin
    getListCartAdmin({ commit }, req) {
      return Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.CART}?${Urls.PAGE}=${req.page}`, req.data)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listCartAdmin", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end api admin

    // api User
    getInfoAuthor({ commit }, request) {
      const api = Api.userRequestServer;
      api
        .post(`/${Urls.AUTHOR}/${request.id}?shop_id=${request.shop_id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["authorById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getInfoContentAuthor({ commit }, formData) {
      const paramData = {
        page: formData.page,
        data: {
          shop_id: formData.shop_id,
          id: formData.id,
          limit: formData.limit,
        },
      };
      const api = Api.userRequestServer;
      api
        .post(
          `/${Urls.AUTHOR}/${paramData.data.id}/${Urls.CONTENT}?${Urls.PAGE}=${paramData.page}`,
          paramData.data
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["contentAuthor", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async deletePage({ commit }, data) {
      return await Api.adminRequestServer
        .delete(`/${Urls.PAGE}/${data.id}?shop_id=${data.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // api cart
    async addToCart({ commit }, formData) {
      const api = Api.userRequestServer;
      return await api
        .post(`/${Urls.CART}/${Urls.CREATE}`, formData)
        .then((response) => {
          const { data } = response;
          if (response.status == 401) {
            redirectLoginShop();
          }
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async getListCart({ commit }, formData) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      return await api
        .post(`/${Urls.CART}/${Urls.INDEX}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listCart", data.data]);
            commit("set", ["amountCart", data.data.length]);
            if (data.data.length) {
              const listContentSet = [];
              if (data.data[0].list_cart && data.data[0].list_cart.length) {
                data.data[0].list_cart.forEach((item) => {
                  listContentSet.push(item.content_id);
                  commit("set", ["listContentCart", listContentSet]);
                });
              }
            }
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    deleteCart({ commit }, request) {
      const api = Api.userRequestServer;
      api
        .delete(
          `/${Urls.CART}/${Urls.DELETE}/${request.idCart}?shop_id=${request.shop_id}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["isDeleteSuccessCart", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end api cart

    getListAuthorInStore({ commit }, request) {
      const api = Api.userRequestServer;
      api
        .get(
          `/${Urls.STORE}/${Urls.FILTER}/${Urls.AUTHOR}?${Urls.PAGE}=${request.page}&shop_id=${request.shop_id}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listAuthor", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getListCategoryInStore({ commit }, request) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .get(
          `/${Urls.STORE}/${Urls.FILTER}/${Urls.CATEGORY}?${Urls.PAGE}=${request.page}&shop_id=${request.shop_id}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listCategory", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getListAuthorFilter({ commit }, request) {
      const paramData = {
        page: request.page ? request.page : 1,
        data: {
          shop_id: request.shop_id,
          limit: request.limit,
        },
      };
      const api = Api.userRequestServer;
      api
        .post(`/${Urls.AUTHOR}?${Urls.PAGE}=${paramData.page}`, paramData.data)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listAuthorFilter", data.data]);
          } else {
            commit("set", ["listAuthorFilter", []]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getListAuthorSuggest({ commit }) {
      let shop_id = this.$route.params.shopId;
      const paramData = {
        shop_id: shop_id,
      };
      const api = Api.userRequestServer;
      api
        .get(`/${Urls.AUTHOR}?shop_id=${shop_id}`, paramData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listAuthorSuggest", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // call api content
    getListContent({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `/${Urls.CONTENT}/${Urls.LIST}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listContent", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async getListContents({ commit }, formData) {
      return await Api.adminRequestServer
        .post(
          `/${Urls.CONTENT}/${Urls.LIST}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listContents", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },

    getAllContents({ commit }, formData) {
      return Api.adminRequestServer
        .post(`/${Urls.CONTENT}/${Urls.ALL}`, formData.data)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listAllContents", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getListNoContent({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `/${Urls.CONTENT}/${Urls.NO_CONTENT}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listNoContent", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    deleteContent({ commit }, req) {
      Api.adminRequestServer
        .delete(`/${Urls.CONTENT}/${req.id}?shop_id=${req.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
            // commit("removeItem", ["listContent", req.id]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async deleteNoteContent({ commit }, formData) {
      const api = Api.userRequestServer;
      return await api
        .delete(`/${Urls.NOTE}/${formData.id}?shop_id=${formData.shop_id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
            commit("removeItem", ["listNote", formData.id]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // end call api content

    async CreatePage({ commit }, formCreatePage) {
      return await Api.adminRequestServer
        .post(`/${Urls.PAGE}`, formCreatePage)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async filterContentLibraryStore({ commit }, formData) {
      return await Api.userRequestServer
        .post(`/${Urls.FILTER}/${Urls.CONTENT}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listContentLibraryStore", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // call api Library
    filterContentInLibrary({ commit }, formData) {
      const api = Api.userRequestServer;
      api
        .post(`/${Urls.LIBRARY}/${Urls.FILTER}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["contentInLibrary", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getContentPage({ commit }, id) {
      const api = Api.userRequestServer;
      api
        .get(`/${Urls.PAGE}/${Urls.INDEX}/${id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["contentPage", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getContentChapter({ commit }, id) {
      const api = Api.userRequestServer;
      api
        .get(`/${Urls.PAGE}/${Urls.SHOW}/${id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["contentChapter", data.data[0].chapter]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListAuthorLibrary({ commit }, id) {
      const api = Api.userRequestServer;
      api
        .post(`/${Urls.LIBRARY}/${Urls.LIST_ALL_AUTHOR}`, {
          shop_id: id,
        })
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listAuthorLibrary", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListCategoryLibrary({ commit }, id) {
      const api = Api.userRequestServer;
      api
        .post(`/${Urls.LIBRARY}/${Urls.LIST_ALL_CATEGORY}`, {
          shop_id: id,
        })
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listCategoryLibrary", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end api library

    // api logout
    logout({ commit }) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .post(`/${Urls.LOGOUT}`)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end api logout
    // api checkout
    async getShopById({ commit }, request) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      return await api
        .post(`/${Urls.SHOP_INFO}`, request)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailShop", data.data]);
            localStorage.setItem(
              Constants.DETAIL_SHOP,
              JSON.stringify(data.data)
            );
            localStorage.setItem(Constants.SHOP_ID, data.data.id);
          } else {
            router.push({
              name: "page404noparam domain",
            });
          }
          return data;
        })
        .catch((error) => {
          router.push({
            name: "page404noparam domain",
          });
        });
    },
    getShopByIdForAdmin({ commit }, id) {
      Api.adminRequestServer
        .get(`/${Urls.SHOP}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailShop", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["message", error.message]);
          commit("set", ["success", true]);
        });
    },
    createOrder({ commit }, formData) {
      const api = Api.userRequestServer;
      api
        .post(`/${Urls.ORDER}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getAllOrders({ commit }, id) {
      const api = Api.adminRequestServer;
      api
        .get(`/${Urls.ORDER}/${Urls.SHOP}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listOrder", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    confirmPaymentComplete({ commit }, formData) {
      const api = Api.adminRequestServer;
      api
        .post(`/${Urls.ORDER}/${Urls.UPDATE_STATUS}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    confirmPaymentNotComplete({ commit }, formData) {
      const api = Api.adminRequestServerr;
      api
        .post(`/${Urls.ORDER}/${Urls.UPDATE_STATUS}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end api checkout
    //create member
    async createMember({ commit }, formData) {
      return await Api.userRequestServer
        .post(`/${Urls.MEMBER}/${Urls.CREATE}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //api note
    async getListNote({ commit }, id) {
      const api = Api.userRequestServer;
      await api
        .get(`/${Urls.NOTE_CONTENT}/${id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listNote", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getListNoteBypage({ commit }, formData) {
      const api = Api.userRequestServer;
      await api
        .post(`/${Urls.PAGE}/${Urls.NOTE}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listNote", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateViewCatalogue({ commit }, formData) {
      const api = Api.userRequestServer;
      await api
        .post(`/${Urls.CATALOGUE}/${Urls.READ}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["error", true]);
            commit("set", ["message", data.message]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          commit("set", ["message", error.message]);
        });
    },
    async createNote({ commit }, formData) {
      const api = Api.userRequestServer;
      return await api
        .post(`/${Urls.NOTE}/${Urls.CREATE}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getNoteByIdAdmin({ commit }, id) {
      const api = Api.adminRequestServer;
      api
        .get(`/${Urls.NOTE}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["noteById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getNoteByIdUser({ commit }, id) {
      const api = Api.userRequestServer;
      api
        .get(`/${Urls.NOTE}/${id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["noteById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateNoteById({ commit }, formUpdate) {
      const api = Api.userRequestServer;
      return await api
        .patch(`/${Urls.NOTE}/${formUpdate.id}`, formUpdate)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.error]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    deleteNote({ commit }, formData) {
      const api = Api.userRequestServer;
      api
        .delete(`/${Urls.NOTE}/${formData.id}?shop_id=${formData.shop_id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
            commit("removeItem", ["listNote", formData.id]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //end api note
    //api setting point
    getListSettingPoint({ commit }, dataForm) {
      Api.adminRequestServer
        .post(
          `/${Urls.LIST_POINT_SHOP}?${Urls.PAGE}=${dataForm.page}`,
          dataForm.data
        )
        .then((response) => {
          const { data } = response;
          commit("set", ["listSettingPoint", data.data]);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    createPoint({ commit }, formData) {
      Api.adminRequestServer
        .post(`/${Urls.POINT}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getSettingPointById({ commit }, data) {
      Api.adminRequestServer
        .get(`/${Urls.POINT}/${data.id}?shop_id=${data.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["settingPointById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    updatePointById({ commit }, formData) {
      Api.adminRequestServer
        .patch(`/${Urls.POINT}/${formData.id}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    deletePoint({ commit }, formData) {
      Api.adminRequestServer
        .post(`/${Urls.POINT}/${Urls.BLOCK}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    clickContent({ commit }, formData) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .post(`/${Urls.POINT}/${Urls.CLICK_ITEM}`, formData)
        .then((response) => {
          const { data } = response;
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getAllHistoryPointById({ commit }) {
      const api = Api.userRequestServer;
      api
        .get(`/${Urls.ALL_HISTORY_POINT}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          commit("set", ["allHistoryPoint", data]);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getAllHistoryPointById_2({ commit }, payload) {
      const { shop_id, limit } = payload.data;
      const api = Api.userRequestServer;
      api
        .get(
          `/${Urls.PAYMENT}/${Urls.POINT}/history/?shop_id=${shop_id}&limit=${limit}&${Urls.PAGE}=${payload.page}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          commit("set", ["allHistoryPoint", data]);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getUserUsablePoint({ commit }, shopId) {
      const api = Api.userRequestServer;
      api
        .get(`/${Urls.USER_POINT}?shop_id=${shopId}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["pointOfUser", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //end api setting point

    //qa
    getListQA({ commit }) {
      let shop_id = this.$route.params.shopId;
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .get(`/${Urls.FILTER_QA}?shop_id=${shop_id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", [
              "listQA",
              data.data.map((item) => {
                const data = new Date(item.created_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                });
                return { ...item, created_at: data };
              }),
            ]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getQAById({ commit }, id) {
      let shop_id = this.$route.params.shopId;
      const api = Api.adminRequestServer;
      api
        .get(`/${Urls.QA}/${id}?shop_id=${shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["qaById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // payment info
    getPaymentInfor({ commit }) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .get(`/${Urls.PAYMENT_INFO}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["paymentInfos", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    //List Note Shop
    getListNoteShop({ commit }, dataForm) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .post(`/${Urls.NOTE_CONTENT}`, dataForm)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listNoteShop", data.data]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getListNoteShopContent({ commit }, dataForm) {
      const api = Api.userRequestServer;
      api
        .post(
          `/${Urls.NOTE_CONTENT}/${Urls.LIST}?${Urls.PAGE}=${dataForm.page}`,
          dataForm.data
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listContentShop", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListNoteShopSP({ commit }, dataForm) {
      const api = Api.userRequestServer;
      api
        .post(
          `/${Urls.NOTE_SHOP}/${Urls.LIST}?${Urls.PAGE}=${dataForm.page}`,
          dataForm.data
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listNoteShopSP", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async getListNoteShopByIdContent({ commit }, dataForm) {
      const api = Api.userRequestServer;
      return await api
        .post(
          `/${Urls.NOTE_CONTENT}/${Urls.SHOW}/${dataForm.data.id}?${Urls.PAGE}=${dataForm.page}`,
          dataForm.data
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listNoteShop", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getListNoteByContent({ commit }, id) {
      Api.adminRequestServer
        .get(`/${Urls.NOTE_CONTENT}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["ListNoteByContent", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListNoteByContentAdmin({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.USER}/${Urls.CONTENT}/${Urls.LIST_NOTE}`,
          formData
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["ListNoteByContent", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getFilterNote({ commit }, formData) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .post(`/${Urls.NOTE}/${Urls.FILTER}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listFilterNote", data.data]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // start API Library
    async getDataLibrary({ commit }, formdata) {
      const api = Api.userRequestServer;
      return await api
        .post(
          `/${Urls.LIBRARY}/${Urls.FILTER}?${Urls.PAGE}=${formdata.page}`,
          formdata
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["dataLibrary", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          // commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListLibraryAuthor({ commit }, formData) {
      const api = Api.userRequestServer;
      api
        .get(
          `/${Urls.LIBRARY}/${Urls.AUTHOR}/${Urls.LIST}?${Urls.PAGE}=${formData.page}&shop_id=${formData.shop_id}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listLibraryAuthor", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListLibraryCategory({ commit }, formData) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .get(
          `/${Urls.LIBRARY}/${Urls.CATEGORY}/${Urls.LIST}?${Urls.PAGE}=${formData.page}&shop_id=${formData.shop_id}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listLibraryCategory", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end API Library
    // start API Store
    getListRecommendInStore({ commit }, formdata) {
      const api = Api.userRequestServer;
      api
        .post(`/${Urls.STORE}/${Urls.FILTER}/${Urls.RECOMMEND}`, formdata)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["dataRecommend", []]);
            commit("set", ["dataRecommend", data.data]);
          } else {
            commit("set", ["error", true]);
            commit("set", ["message", data.message]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          commit("set", ["message", error.message]);
        });
    },
    async getDataStore({ commit }, formdata) {
      const api = Api.userRequestServer;
      return await api
        .post(
          `/${Urls.STORE}/${Urls.FILTER}?${Urls.PAGE}=${formdata.page}`,
          formdata
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["dataStore", []]);
            commit("set", ["dataStore", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getDetailStore({ commit }, formdata) {
      const api = Api.userRequestServer;
      return await api
        .get(`/${Urls.STORE}/${formdata.id}?shop_id=${formdata.shopId}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["detailStore", data.data]);
          } else {
            // commit("set", ["error", true]);
            // commit("set", ["message", data.message]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          commit("set", ["message", error.message]);
        });
    },
    getDetailContentPreSales({ commit }, formdata) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .get(
          `/${Urls.CONTENT}/${Urls.PRE_SALE}/${formdata.id}?shop_id=${formdata.shopId}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["detailcontentPreSales", data.data]);
          } else {
            // commit("set", ["error", true]);
            // commit("set", ["message", data.message]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          commit("set", ["message", error.message]);
        });
    },
    async getDetailContentPayment({ commit }, formdata) {
      const api = Api.userRequestServer;
      return await api
        .get(
          `/${Urls.CONTENT}/${Urls.NO_CONDITION}/${formdata.id}?shop_id=${formdata.shopId}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["detailStore", data.data]);
          } else {
            commit("set", ["error", true]);
            commit("set", ["message", data.message]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          commit("set", ["message", error.message]);
        });
    },
    async getInforAuthorByContent({ commit }, formdata) {
      return await Api.userRequestServer
        .get(
          `/${Urls.AUTHOR_CONTENT}/${formdata.id}?shop_id=${formdata.shopId}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["authorById", data.data]);
          } else {
            commit("set", ["error", true]);
            commit("set", ["message", data.message]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          commit("set", ["message", error.message]);
        });
    },
    // end API Store

    // API SaleManagetment
    getSaleManagetmentMonthly({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `${Urls.ADMIN}/${Urls.SALES_MANAGEMENT}/${Urls.MONTHLY}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listSaleManagementMonthly", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getSumSaleManagetmentMonthly({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `${Urls.ADMIN}/${Urls.SALES_MANAGEMENT}/${Urls.MONTHLY_SUM}`,
          formData
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["dataSumSaleManagementMonthly", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getSaleManagetmentDaily({ commit }, formData) {
      Api.adminRequestServer
        .post(`${Urls.ADMIN}/${Urls.SALES_MANAGEMENT}/${Urls.DAILY}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listSaleManagementDaily", data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getDetailSaleManagetmentDaily({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `${Urls.ADMIN}/${Urls.SALES_MANAGEMENT}/${Urls.DAILY_DETAIL}`,
          formData
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listDetailSaleManagementDaily", data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getDetailSaleManagetmentRank({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `${Urls.ADMIN}/${Urls.SALES_MANAGEMENT}/${Urls.RANK}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listSaleManagementRank", data.data]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getDateSaleManagetmentRank({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `${Urls.ADMIN}/${Urls.SALES_MANAGEMENT}/${Urls.RANK}/${Urls.DAY}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["dateSaleManagementRank", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end API SaleManagetment

    //API setting Page
    getListSettingPageHeader({ commit }, id) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      let formData = {
        shop_id: id,
      };
      api
        .post(`/${Urls.HEADER}/${Urls.LIST}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listSettingPageHeader", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getListSettingShopAdmin({ commit }, formData) {
      const api = Api.adminRequestServer;
      return await api
        .post(`${Urls.SHOP}/${Urls.SETTING}/${Urls.GENERAL}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["ListSettingShop", data.data]);
            if (data.data.length) {
              localStorage.setItem(
                Constants.TITLE_SHOP,
                data.data[0].shop_title
              );
            }
            return data;
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getListSettingShopUser({ commit }, formData) {
      const api = Api.userRequestServer;
      return await api
        .post(`${Urls.SHOP}/${Urls.SETTING}/${Urls.GENERAL}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["ListSettingShop", data.data]);
            if (data.data.length) {
              localStorage.setItem(
                Constants.TITLE_SHOP,
                data.data[0].shop_title
              );
            }
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListSettingPageFooter({ commit }) {
      let shop_id = this.$route.params.shopId;
      let formData = {
        shop_id: shop_id,
      };
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .post(
          `${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.FOOTER}/${Urls.LIST}`,
          formData
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listSettingPageFooter", data.data]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //Get all tag
    async getListTag({ commit }, shop_id) {
      const api = Api.userRequestServer;
      await api
        .get(`/${Urls.TAG}?shop_id=${shop_id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listTag", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async createNewTag({ commit }, formData) {
      const api = Api.userRequestServer;
      return await api
        .post(`/${Urls.TAG}/${Urls.CREATE}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListOrderSubcription({ commit }, req) {
      const url = `${Urls.ORDER}/${Urls.LIST}`;
      const api = Api.userRequestServer;
      api
        .post(url, req)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listOrderSub", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async deleteAccount({ commit }, data) {
      const url = `/${Urls.PROFILE}?shop_id=${data.shop_id}`;
      const api = Api.userRequestServer;
      return await api
        .delete(url)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // API Timing Pop-up
    async getListTimingPopUp({ commit }, formData) {
      await Api.adminRequestServer
        .post(
          `${Urls.ADMIN}/${Urls.POPUP}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listTimingPopup", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async createTimingPopUp({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`${Urls.ADMIN}/${Urls.POPUP}/${Urls.CREATE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async updateTimingPopUpByID({ commit }, formData) {
      return await Api.adminRequestServer
        .patch(`${Urls.ADMIN}/${Urls.POPUP}/${formData.id}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getTimingPopUpById({ commit }, formData) {
      Api.adminRequestServer
        .get(
          `/${Urls.ADMIN}/${Urls.POPUP}/${formData.id}?shop_id=${formData.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["timingPopupById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async deleteTimingPopUp({ commit }, data) {
      return await Api.adminRequestServer
        .delete(
          `/${Urls.ADMIN}/${Urls.POPUP}/${data.id}?shop_id=${data.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getlistTimingPopUpUser({ commit }, formData) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .post(`/${Urls.POPUP}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listPopUp", data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListFooter({ commit }, reqData) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .post(`/${Urls.FOOTER}/${Urls.LIST}`, reqData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listFooter", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListBottom({ commit }, reqData) {
      const api = Api.userRequestServer;
      api
        .post(`/${Urls.BOTTOM}/${Urls.LIST}`, reqData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listBottom", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getListUrlUser({ commit }, formData) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      return await api
        .post(`/${Urls.ADMIN}/${Urls.URL}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listUrlUser", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getFixedPageUser({ commit }, reqData) {
      const api = Api.userRequestServer;
      return await api
        .get(`/${Urls.FIXED_PAGE}/${reqData.id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["detailFixedPage", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getFixedPage({ commit }, reqData) {
      const api = Api.userRequestServer;
      return await api
        .get(`/${Urls.FIXED_PAGE}/${reqData.id}?is_click=${reqData.is_click}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["detailFixed", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getListSlagUser({ commit }, req) {
      const api = Api.userRequestServer;
      api
        .get(`/${Urls.USER}/${req.user_id}/${Urls.SLAG}?shop_id=${req.shop_id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listSlagUser", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end API Timing Popup

    //Sale Setting
    async createSaleSetting({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`/${Urls.SETTING}/${Urls.SALE_INFO}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // end Sale Setting

    // Timing Mail
    getListTimingmail({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `${Urls.ADMIN}/${Urls.TIMING_MAIL}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listTimingMail", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async deleteTimingMail({ commit }, data) {
      return await Api.adminRequestServer
        .delete(
          `/${Urls.ADMIN}/${Urls.TIMING_MAIL}/${data.id}?shop_id=${data.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async createTimingMail({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.TIMING_MAIL}/${Urls.CREATE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getTimingMailById({ commit }, formData) {
      Api.adminRequestServer
        .get(
          `/${Urls.ADMIN}/${Urls.TIMING_MAIL}/${formData.id}?shop_id=${formData.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["timingMailById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async updateTimingMailByID({ commit }, formData) {
      return await Api.adminRequestServer
        .patch(`${Urls.ADMIN}/${Urls.TIMING_MAIL}/${formData.id}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async checkConditionTimingMail({ commit }, formData) {
      await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.TIMING_MAIL}/${Urls.CHECK}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["timingMailUserSend", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // End Timing Mail

    async getListAuthorAdmin({ commit }, formData) {
      await Api.adminRequestServer
        .post(
          `/${Urls.ADMIN}/${Urls.AUTHOR}/${Urls.LIST}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listAuthor", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // Gerenal Setting
    GerenalSetting({ commit }, formCreate) {
      return Api.adminRequestServer
        .post(`/${Urls.SETTING}/${Urls.GENERAL}`, formCreate)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          commit("set", ["loading", false]);
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getGeneralSettingByShopId({ commit }, shopId) {
      Api.adminRequestServer
        .get(`/${Urls.SETTING}/${Urls.GENERAL}/${shopId}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["generalsetting", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    getGeneralSettingByShopIdUser({ commit }, shopId) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .get(`/${Urls.SHOP}/${Urls.SETTING}/${Urls.GENERAL}/${shopId}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["generalsetting", data.data]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    //End Gerenal Setting

    async getSaleSettingAdmin({ commit }, shop_id) {
      const api = Api.adminRequestServer;
      return await api
        .get(`/${Urls.SETTING}/${Urls.SALE}/${shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["saleSettingById", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getSaleSettingUser({ commit }, shop_id) {
      const api = Api.userRequestServer;
      return await api
        .get(`/${Urls.SETTING}/${Urls.SALE}/${shop_id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["saleSettingById", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // SNS Setting
    getDetailSNSSetting({ commit }, data) {
      Api.adminRequestServer
        .post(`/${Urls.SETTING}/${Urls.SNS_LOGIN}`, data)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["SNSSetting", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async updateSNSSetting({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`/${Urls.SETTING}/${Urls.SNS_LOGIN}/${Urls.UPDATE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // End SNS Setting

    CancelPopup({ commit }, formData) {
      const api = Api.userRequestServer;
      return api
        .post(`/${Urls.POPUP}/${Urls.CANCEL}`, formData)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          commit("set", ["loading", false]);
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // API Mail Mega
    getlistMailMega({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `${Urls.ADMIN}/${Urls.NEW_LETTER}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listMailMega", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async deleteMailMega({ commit }, data) {
      return await Api.adminRequestServer
        .delete(
          `/${Urls.ADMIN}/${Urls.NEW_LETTER}/${data.id}?shop_id=${data.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async createMailMega({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.NEW_LETTER}/${Urls.CREATE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getMailMegaById({ commit }, formData) {
      Api.adminRequestServer
        .get(
          `/${Urls.ADMIN}/${Urls.NEW_LETTER}/${formData.id}?shop_id=${formData.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["mailMegaById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async updateMailMegaByID({ commit }, formData) {
      return await Api.adminRequestServer
        .patch(`${Urls.ADMIN}/${Urls.NEW_LETTER}/${formData.id}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async getFixedPageByIdScreenUser({ commit }, reqData) {
      return await Api.userRequestServer
        .get(`/${Urls.FIXED_PAGE}/${reqData.id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["detailFixedPageByUser", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    //API Chapter
    getAllChapter({ commit }, formData) {
      const url = `${Urls.CHAPTER}?shop_id=${formData.data.shop_id}?${Urls.PAGE}=${formData.page}`;
      Api.adminRequestServer
        .get(url, formData.data)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listAllChapter", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async updateChapterSettingByID({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`${Urls.ADMIN}/${Urls.CHAPTER}/${Urls.UPDATE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            // commit("set", ["message", data.message]);
            // commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async createBasicContent({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`${Urls.CONTENT}/${Urls.SETTINGBASIC}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateSetBasicContent({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`${Urls.CONTENT}/${Urls.UPDATEBASIC}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async createSaleContent({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`${Urls.CONTENT}/${Urls.SETTINGSALE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateSaleContent({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`${Urls.CONTENT}/${Urls.UPDATESALE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getInfoSlag({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`${Urls.ADMIN}/${Urls.SLAG}/${Urls.CONTENT}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // async CreateCategoryAdmin({ commit }, formCreate) {
    //   return await Api.adminRequestServer
    //     .post(`${Urls.ADMIN}/${Urls.CATEGORY}/${Urls.CREATE}`, formCreate)
    //     .then((response) => {
    //       const { data } = response;
    //       if (data.success) {
    //         commit("set", ["message", data.message]);
    //         commit("set", ["success", true]);
    //       } else {
    //         commit("set", ["message", data.message]);
    //         commit("set", ["error", true]);
    //       }
    //       return data;
    //     })
    //     .catch((error) => {
    //
    //   commit("set", ["error", true]);
    //   return { success: false };
    // });
    // },

    // async deleteCategory({ commit }, data) {
    //   return await Api.adminRequestServer
    //     .delete(
    //       `${Urls.ADMIN}/${Urls.CATEGORY}/${data.id}?shop_id=${data.shop_id}`
    //     )
    //     .then((response) => {
    //       const { data } = response;
    //       if (data.success) {
    //         commit("set", ["success", true]);
    //         commit("set", ["message", data.message]);
    //       } else {
    //         commit("set", ["message", data.message]);
    //         commit("set", ["error", true]);
    //       }
    //       return data;
    //     })
    //     .catch((error) => {
    //
    //   commit("set", ["error", true]);
    //   return { success: false };
    // });
    // },

    getChapterById({ commit }, id) {
      Api.adminRequestServer
        .get(`${Urls.CHAPTER}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["chapterById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateChapterByID({ commit }, dataForm) {
      return await Api.adminRequestServer
        .patch(
          `${Urls.CHAPTER}/${dataForm.id}?shop_id=${dataForm.shop_id}`,
          dataForm
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //END Api chapter

    //API Point Definition
    getListPurchasePoint({ commit }, formData) {
      Api.adminRequestServer
        .post(
          `${Urls.ADMIN}/${Urls.PURCHASE_POINT}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listPurchasePoint", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async CreatePurchasePoint({ commit }, formCreate) {
      return await Api.adminRequestServer
        // .post(`${Urls.ADMIN}/${Urls.POINT_BONUS}/${Urls.CREATE}`, formCreate)
        //If not exist, then create, else update
        .post(`${Urls.POINT_BONUS}/${Urls.CREATE}`, formCreate)
        .then((response) => {
          const { data } = response;
          // console.log(response);
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async deletePurchasePoint({ commit }, data) {
      return await Api.adminRequestServer
        .delete(
          `${Urls.ADMIN}/${Urls.PURCHASE_POINT}/${data.id}?shop_id=${data.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getPurchasePointById({ commit, state }, data) {
      Api.adminRequestServer
        // .get(
        //   `${Urls.ADMIN}/${Urls.PURCHASE_POINT}/${data.id}?shop_id=${data.shop_id}`
        // )
        .post(`${Urls.POINT_BONUS}`, data)
        .then((response) => {
          const { data } = response;
          // eslint-disable-next-line no-prototype-builtins
          if (data.success && data.hasOwnProperty("data")) {
            commit("set", ["PurchasePointById", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updatePurchasePoint({ commit }, data) {
      return await Api.adminRequestServer
        .patch(
          // `${Urls.ADMIN}/${Urls.PURCHASE_POINT}/${data.id}?shop_id=${data.shop_id}`,
          `${Urls.POINT_BONUS}/${Urls.UPDATE}`,
          data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //END Point Definition
    // Chpater Setting
    getListChapterSetting({ commit }, formData) {
      Api.adminRequestServer
        .post(`${Urls.ADMIN}/${Urls.CHAPTER}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listChapterSetting", data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getChapterSetting({ commit }, formData) {
      Api.adminRequestServer
        .post(`${Urls.ADMIN}/${Urls.CHAPTER}/${Urls.SETTING}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["ChapterSettingByContent", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateChapterSetting({ commit }, data) {
      return await Api.adminRequestServer
        .post(
          `${Urls.ADMIN}/${Urls.CHAPTER}/${Urls.SETTING}/${Urls.UPDATE}`,
          data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    //END Chpater Setting

    // API Find by Token
    async getURLByToken({ commit }, data) {
      return await Api.adminRequestServer
        .post(`${Urls.ADMIN}/${Urls.SLAG}/${Urls.TOKEN}`, data)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["URLByToken", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    //Get Info content before Login
    getInforContentPayment({ commit }, id) {
      Api.userRequestServer
        .get(`${Urls.CONTENT}/${Urls.PAYMENT}/${Urls.INFO}/${id}`)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["InfoContentPayment", data.data]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async createQuickUser({ commit }, formCreate) {
      return await Api.userRequestServer
        .post(`/${Urls.PROFILE}`, formCreate)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async activeAccount({ commit }, formCreate) {
      return await Api.userRequestServer
        .post(`${Urls.ACTIVE_SHOP}`, formCreate)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    getGeneralByIdContent({ commit }, id) {
      Api.userRequestServer
        .get(`${Urls.SETTING}/${Urls.SNS}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["dataGeneral", data.data]);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // show condition chapter //
    async getConditionChapterAndPage({ commit }, dataRequest) {
      return await Api.adminRequestServer
        .post(`${Urls.CONTENT}/${Urls.CHAPTER}/${Urls.PAGE}`, dataRequest)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["dataConditionChapterPage", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getConditionChapter({ commit }, dataRequest) {
      return await Api.adminRequestServer
        .post(`${Urls.CONTENT}/${Urls.CHAPTER}/${Urls.PAGE}`, dataRequest)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["dataConditionChapter", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // API Chat //
    async getListChat({ commit }, dataRequest) {
      return await Api.userRequestServer
        .post(
          `${Urls.CONTENT}/${Urls.CHAT}?${Urls.PAGE}=${dataRequest.page}`,
          dataRequest.data
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listChat", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async createChat({ commit }, dataRequest) {
      return await Api.userRequestServer
        .post(`${Urls.CONTENT}/${Urls.CHAT}/${Urls.CREATE}`, dataRequest)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async getMessageByChatID({ commit }, dataRequest) {
      await Api.userRequestServer
        .get(
          `${Urls.CHAT}/${dataRequest.idChat}?shop_id=${dataRequest.shop_id}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listMessageByChatID", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async getChatByPageId({ commit }, dataRequest) {
      await Api.userRequestServer
        .post(`${Urls.PAGE}/${Urls.CHAT}`, dataRequest)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listMessageByChatID", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async sendMessage({ commit }, dataRequest) {
      return await Api.userRequestServer
        .post(`${Urls.CHAT}/${Urls.MESSAGE}/${Urls.SEND}`, dataRequest)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async deleteMessageByID({ commit }, dataRequest) {
      return await Api.userRequestServer
        .delete(
          `/${Urls.CHAT}/${Urls.MESSAGE}/${dataRequest.id}?shop_id=${dataRequest.shop_id}`
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async getAllListChat({ commit }, dataRequest) {
      return await Api.userRequestServer
        .post(
          `${Urls.CONTENT}/${Urls.CHAT}/${Urls.LIST}?${Urls.PAGE}=${dataRequest.page}`,
          dataRequest.data
        )
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["listChat", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    // End API Chat //

    // API Chat Admin //
    async getListChatByUser({ commit }, dataRequest) {
      return await Api.adminRequestServer
        .post(
          `/${Urls.CHAT}?${Urls.PAGE}=${dataRequest.page}`,
          dataRequest.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["ListChatByUser", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async readPopupChat({ commit }, dataRequest) {
      return await Api.userRequestServer
        .post(`/${Urls.CONTENT}/${Urls.CHAT}/${Urls.READ}`, dataRequest)
        .then((response) => {
          if (response.status == 401) {
            redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async sendMessageAdmin({ commit }, dataRequest) {
      return await Api.adminRequestServer
        .post(`${Urls.CHAT}/${Urls.MESSAGE}/${Urls.SEND}`, dataRequest)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async logActive({ commit }, request) {
      return await Api.userRequestServer
        .post(`/${Urls.LOG_ACTIVE}`, request)
        .then((response) => {
          const { data } = response;
          commit("set", ["refer_url", ""]);
          return data;
        })
        .catch((error) => {
          console.log(error);
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    // API Chat Admin //

    // API Chapter New //
    async createChapterNew({ commit }, dataRequest) {
      return await Api.adminRequestServer
        .post(`${Urls.ADMIN}/${Urls.CHAPTER_NEW}/${Urls.CREATE}`, dataRequest)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async updateChapterNew({ commit }, dataRequest) {
      return await Api.adminRequestServer
        .patch(
          `${Urls.ADMIN}/${Urls.CHAPTER_NEW}/${dataRequest.id}?shop_id=${dataRequest.shop_id}`,
          dataRequest
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getChapterNewById({ commit }, id) {
      Api.adminRequestServer
        .get(`${Urls.ADMIN}/${Urls.CHAPTER_NEW}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["chapterById", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async deleteChapter({ commit }, formData) {
      return await Api.adminRequestServer
        .delete(
          `${Urls.ADMIN}/${Urls.CHAPTER_NEW}/${formData.id}?shop_id=${formData.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getListChapterNew({ commit }, formData) {
      let url = `${Urls.ADMIN}/${Urls.CHAPTER_NEW}?shop_id=${formData.data.shop_id}&${Urls.PAGE}=${formData.page}`;
      url += formData.data.limit ? `&${Urls.LIMIT}=${formData.data.limit}` : "";
      await Api.adminRequestServer
        .get(url, formData.data)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listChapter", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async setSlagBlock({ commit }, formData) {
      console.log(formData, "formData");
      await Api.adminRequestServer
        .post(`${Urls.SLAG_CONDITION}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["successSlagCondition", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async getMessageByChatIDAdmin({ commit }, dataRequest) {
      return await Api.adminRequestServer
        .get(
          `${Urls.CHAT}/${dataRequest.idChat}?shop_id=${dataRequest.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listMessageByChatID", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async deleteMessageByIDAdmin({ commit }, dataRequest) {
      return await Api.adminRequestServer
        .delete(
          `/${Urls.CHAT}/${Urls.MESSAGE}/${dataRequest.id}?shop_id=${dataRequest.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async CheckActiveMember({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.USER}/${Urls.CHECK_ACTIVE}`, formData)
        .then((response) => {
          const { data } = response;
          // eslint-disable-next-line no-empty
          if (data.success) {
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["message2", data.message2]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    createUserOwnerIsNew({ commit }, formData) {
      Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.USER}/${Urls.CREATE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", "サインアップの成功"]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async countViewPDF({ commit }, req) {
      return await Api.userRequestServer
        .post(`/${Urls.COUNT_VIEW_PDF}`, req)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          commit("set", ["error", true]);
          console.log(error);
        });
    },
    async checkTokenUrl({ commit }, req) {
      return await Api.userRequestServer
        .post(`/${Urls.URL}/token`, req)
        .then((response) => {
          const { data } = response;
          return data.success;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    // Coupon
    async getListCoupon({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.COUPON}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listCoupon", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async createCoupon({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.COUPON}/${Urls.CREATE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async showCoupon({ commit }, req) {
      return await Api.adminRequestServer
        .get(`/${Urls.ADMIN}/${Urls.COUPON}/${req.id}/?shop_id=${req.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailCoupon", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async editCoupon({ commit }, req) {
      return await Api.adminRequestServer
        .patch(`/${Urls.ADMIN}/${Urls.COUPON}/${req.id}`, req)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async checkCoupon({ commit }, req) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.COUPON}/${Urls.CHECK}`, req)
        .then((response) => {
          const { data } = response;
          if (response.status == 401) {
            redirectLoginShop();
          }
          if (data.success) {
            commit("set", ["detailCouponChecked", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    // Sentence
    async getListSentence({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.SENTENCE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listSentence", data.data]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async createSentence({ commit }, formData) {
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.SENTENCE}/${Urls.CREATE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async showSentence({ commit }, req) {
      return await Api.adminRequestServer
        .get(
          `/${Urls.ADMIN}/${Urls.SENTENCE}/${req.id}/?shop_id=${req.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailSentence", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async editSentence({ commit }, req) {
      return await Api.adminRequestServer
        .patch(`/${Urls.ADMIN}/${Urls.SENTENCE}/${req.id}`, req)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["message", data.message]);
            commit("set", ["success", true]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async deleteSentence({ commit }, data) {
      return await Api.adminRequestServer
        .delete(
          `/${Urls.ADMIN}/${Urls.SENTENCE}/${data.id}?shop_id=${data.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },
    async checkSentence({ commit }, formData) {
      return await Api.userRequestServer
        .post(`/${Urls.SENTENCE}/${Urls.CHANGE}`, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailCheckSentence", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },

    async updateMyProfileSetting({ commit }, req) {
      return await Api.adminRequestServer
        .post(`/${Urls.SETTING}/${Urls.MY_PROFILE}`, req)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async showMyProfileSetting({ commit }, req) {
      return await Api.adminRequestServer
        .get(`/${Urls.SETTING}/${Urls.MY_PROFILE}/${req.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailMyProfileSetting", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },

    async showMyProfileSettingUser({ commit }, req) {
      return await Api.userRequestServer
        .get(`/${Urls.SETTING}/${Urls.MY_PROFILE}/${req.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailMyProfileSettingUser", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async addRealtimeActivity({ commit }, req) {
      if (!req.user_id || !req.shop_id) return;
      return await Api.userRequestServer
        .post(`/realtime-activity`, req)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async deleteRealtimeActivity({ commit }, req) {
      if (!req.user_id || !req.shop_id) return;
      return await Api.userRequestServer
        .post(`/realtime-activity/delete`, req)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return;
        });
    },
    async storeFixedQuestion({ commit }, req) {
      return await Api.userRequestServer
        .post(`/fixed-question`, req)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async getListFixedQuestion({ commit }, req) {
      return await Api.adminRequestServer
        .post(`/fixed-question/list`, req)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["listFixedQuestion", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
    async givePageThank({ commit }, req) {
      return await Api.userRequestServer
        .post(`/give-page-thank`, req)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },

    async updateDesignSetting({ commit }, req) {
      return await Api.adminRequestServer
        .post(`/${Urls.SETTING}/${Urls.DESIGN}`, req)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["success", true]);
            commit("set", ["message", data.message]);
          } else {
            commit("set", ["message", data.message]);
            commit("set", ["error", true]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
          return { success: false };
        });
    },

    async showDesignSetting({ commit }, req) {
      return await Api.adminRequestServer
        .get(`/${Urls.SETTING}/${Urls.DESIGN}?shop_id=${req.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailDesignSetting", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginAdmin();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },

    async showDesignSettingUser({ commit }, req) {
      return await Api.userRequestServer
        .get(`/${Urls.SETTING}/${Urls.DESIGN}?shop_id=${req.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            commit("set", ["detailDesignSetting", data.data]);
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            redirectLoginShop();
          } else {
            console.log(error);
          }
          commit("set", ["error", true]);
        });
    },
  },
  modules: {},
  plugins: [logrocketPlugin],
});
