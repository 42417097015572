/* eslint-disable prettier/prettier */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CoreuiVue from '@coreui/vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import Toaster from 'v-toaster'
import 'v-toaster/dist/v-toaster.css'
import { ModelSelect } from 'vue-search-select'
import { freeSet } from '@coreui/icons'
import Multiselect from "vue-multiselect";
import VueLoading from 'vuejs-loading-plugin'
import VueFbCustomerChat from 'vue-fb-customer-chat'
import Toasted from 'vue-toasted';
import datePicker from 'vue-bootstrap-datetimepicker';
import { Chrome } from 'vue-color';
import VueClipboard from 'vue-clipboard2';
import vueVimeoPlayer from 'vue-vimeo-player';
import VueYoutube from 'vue-youtube';
import Editor from 'vue-editor-js/src';
import VueCompositionApi from '@vue/composition-api';
import VueScrollTo  from 'vue-scrollto'
export const eventBus = new Vue()

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('chrome-picker', Chrome)
Vue.component('multiselect', Multiselect)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('model-select', ModelSelect)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.component('date-picker', datePicker);


library.add(fab)
library.add(fas)

Vue.config.productionTip = false;
Vue.use(VueClipboard);
Vue.use(CoreuiVue)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Toaster, {timeout: 5000})
Vue.use(VueLoading)
Vue.use(vueVimeoPlayer)
Vue.use(VueYoutube)
Vue.use(Editor)
Vue.use(VueCompositionApi)
Vue.use(VueScrollTo)

Vue.use(Toasted, {
  theme: "bubble",
  iconPack : 'custom-class',
  position: "top-right",
  // transition: "transform 0.6s cubic-bezier(0.22, 1, 0.36, 1)",
  duration: 1500,
  action: [
    {
      icon : 'fas fa-times',
      // text: 'X',
      onClick: (e, toastObject) => {
        toastObject.goAway(0);
      },
    },
  ],
})
Vue.use(VueFbCustomerChat, {
  page_id: null, //  change 'null' to your Facebook Page ID,
  theme_color: '#333333', // theme color in HEX
  locale: 'en_US', // default 'en_US'
})

new Vue({
  router,
  store,
  icons: freeSet,
  render: (h) => h(App),
}).$mount("#app");
