export const Routes = {
  DASHBOARD: "dashboard",
  LOGIN: "login",
  CREATESLAG: "create-slag",
  SLAG: "slag",
  EDITSLAG: "edit-slag",
  OWNER: "owner",
  CREATE: "create",
  EDIT: "edit",
  DETAIL: "detail",
  CONTENT: "content",
  MEMBER: "member",
  LIBRARY: "library",
  STORE: "store",
  PAGE: "page",
  FORGOTPASSWORD: "forgot-password",
  CHANGEPASSWORD: "change-password",
  FACEBOOK: "auth/callback/facebook",
  ADMIN: "admin",
  LINE: "auth/callback/line",
  CART: "cart",
  STOREAUTHORS: "store-authors",
  AUTHOR: "author",
  USER: "user",
  CHECKOUT: "checkout",
  CONFIRM_CHECKOUT: "confirm-checkout",
  TWITTER: "auth/callback/twitter",
  AMAZON: "auth/callback/amazon",
  POINT: "point",
  HISTORY: "history",
  QA: "qa",
  ACTIVEMAIL: "active/account",
  OTHER: "other",
  TABLEOFCONTENTEDIT: "catalogues",
  SHOP: "shop",
  SIGNUP: "signup",
  HOME: "home",
  CATEGORY: "category",
  PAGES: "pages",
  NOTES: "notes",
  ORDER: "order",
  NOTE: "note",
  PAYMENT: "payment",
  CONFIRM_PAYMENT: "confirm_payment",
  NOTE_CONTENT: "note-content",
  NO: "no",
  SALES_MANAGEMENT: "sales-management",
  DAILY: "daily",
  MONTHLY: "monthly",
  MY_PROFILE: "my-profile",
  PURCHASE: "purchase",
  NEWS: "news",
  SETTING: "setting",
  MENU: "menu",
  BOTTOM: "bottom-navigation",
  RANK: "rank",
  EMAIL_SETTING: "email-setting",
  FIXED_PAGE: "fixed-page",
  FIXED_PAGE_COLUMN: "fixed-page-column",
  PREVIEW: "preview",
  PREVIEW_COLUMN: "pre_column_page",
  INQUIRY: "inquiry",
  TIMING_POP_UP: "timing-pop-up",
  CDEA_ADMIN: "cdea_admin",
  TIMING_MAIL: "timing-mail",
  SALE: "sale",
  OWNERS: "owners",
  HEADER: "header",
  FOOTER: "footer",
  GENERAL: "general",
  URL: "url",
  SNS: "sns",
  DEL_ACC: "delete-account",
  CHANGE: "change",
  TRIGGER: "trigger",
  MAIL_MEGA: "mail_mega",
  SURVEY: "survey",
  CHAPTER: "chapter",
  HELP_PAGE: "help-page",
  CATEGORY_NEW: "category-new",
  POINT_DEFINITION: "purchase-point",
  BUY: "buy",
  BOX: "box",
  LIBRARY_TEST: "library-test",
  QUESTION: "question",
  CHAT_CONTENT: "chat-content",
  THANK: "thank",
  POINT_BONUS: "point-bonus",
  CHAT: "chat",
  DISPLAY_CONTENT: "display-content",
  NOTICE: "notice",
  INVOICE: "invoice",
  COUPON: "coupon",
  MAINTENANCE: "maintenance",
  SENTENCE: "sentence",
  REALTIME_ACTIVITY: "realtime-activity",
  DESIGN_SETTING: "design-setting",
  EXTERNAL_PAYMENT_REDIRECT: "external-payment-redirect",
  EXTERNAL_PAYMENT_CHECK: "external-payment-check",
  EXTERNAL_PAYMENT_APPROVAL: "external-payment-approval",
};
